.overlay {
  background: #3a3a3a;
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 9999em;
  color: rgba(11, 57, 84, 0.55);
}

footer {
  font-size: 1.3rem;
  background-size: cover;
  .footer-title {
    position: relative;
    margin-bottom: 2rem;
    color: #ffffff;
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 50px;
      height: 2px;
      background: #248eff;
      top: 30px;
    }
  }

  .footer-content {
    color: #6c757d;
    width: 90%;
  }
  .bottom-footer {
    background-color: #181d21;
    color: #ffffff;
  }
  .quick-links {
    list-style-type: none;
    padding: 0;
    margin: 0;

    a {
      text-decoration: none;
      font-size: 1rem;
      display: inline-block;
      padding: 0.3rem 0;
      color: #d0d0d0 !important;
      &:hover {
        color: lighten(#ffffff, 20%) !important;
      }
    }
  }
  a {
    text-decoration: none;
    font-size: 1rem;
    display: inline-block;
    padding: 0.3rem 0;
    color: #d0d0d0 !important;
    &:hover {
      text-decoration: none;
      color: lighten(#ffffff, 20%) !important;
    }
  }
}
//6c757d
//bdbdbd

#others {
  background: white;
  padding-top: 4rem;
  padding-bottom: 2rem;

  a {
    text-decoration: none;
    font-size: 1rem;
    display: inline-block;
    padding: 0.3rem 0;
    color: #0073ad !important;
    &:hover {
      text-decoration: none;
      color: lighten(#000000, 20%) !important;
    }
  }
}