#focus {
  background-color: #f6f8fb;
}

.focusImage {
  width: 100%;
  margin-bottom: 50px;
  // max-height: 300px;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
.focus-description {
  font-size: 1rem;
  color: #444444;
}
