.work-card {
    margin-bottom: 50px;
}
.work-img {
    height: 150px;
    @media (max-width: 768px) {
      height: 150px;
    }
    @media (min-width: 1000px) {
      height: 150px;
    }
}

#works {
    a {
        text-decoration: none;
    }
    .card-title {
        :hover {
            font-size: 1.3rem;
        }
    }
}