#me {
  .card-img-top {
    object-fit: cover;
    height: 350px;
    @media (max-width: 768px) {
      height: 250px;
    }
    @media (min-width: 1000px) {
      height: 250px;
    }
  }
  .card-body {
    a {
      background: lighten(#0073ad, 10%);
    }
  }
  .card {
    box-shadow: 0 10px 30px 0 rgba(50, 50, 50, 0.16);
    border: none;
    .card-title {
      font-size: 1.2rem;
      font-weight: bolder;
      color: darken(#0073ad, 0%);
      text-transform: uppercase;
    }
    .card-text {
      font-size: 1rem;
      color: #6f6f6f;
    }
  }
}
